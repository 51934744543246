import React from 'react'
import { Helmet } from '../components/Shared'
import Downloads, { helmetProps } from '../components/Downloads'

export default React.memo(props => (
  <>
    <Helmet {...helmetProps} />
    <Downloads {...props} />
  </>
))
